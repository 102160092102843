<template>
  <v-dialog
      v-model="dialog"
      max-width="800"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Popup</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="popup.name"
                  :error="$v.popup.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.popup.name.$error">Name is required</span>
              <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="popup.type"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  :error="$v.popup.type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.popup.type.$error">Position is required</span>
              <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menuStartDate"
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="popup.start_date"
                      label="Start Date"
                      readonly
                      outlined
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="popup.start_date"
                    outlined
                    dense
                    no-title
                    :min="currentDate"
                    @input="menuStartDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                  ref="menuEndDate"
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="popup.end_date"
                      label="End Date"
                      readonly
                      outlined
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="popup.end_date"
                    outlined
                    dense
                    no-title
                    :min="currentDate"
                    @input="menuEndDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" v-if="popup.type =='image'">
              <v-file-input
                  label="File"
                  v-model="popup.uploadFile"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  outlined
                  dense
              >
              </v-file-input>
            </v-col>

            <v-col cols="12" v-if="popup.type =='text'">
              <v-text-field
                  label="Link Url"
                  v-model="popup.link_url"
                  :error="$v.popup.link_url.$error"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger" v-if="!$v.popup.link_url.url">Url should be Valid</span>
            </v-col>

            <v-col cols="12" v-if="popup.type =='text'">
              <v-text-field
                  label="Video Link Url"
                  v-model="popup.video_link_url"
                  :error="$v.popup.video_link_url.$error"
                  outlined
                  dense
              >
              </v-text-field>
              <span class="text-danger" v-if="!$v.popup.video_link_url.url">Url should be Valid</span>
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Applicable Pages"
                  item-text="name"
                  item-value="value"
                  v-model="popup.applicable_pages"
                  :items="pages"
                  multiple
                  small-chips
                  deletable-chips
                  outlined
                  dense
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectGrades"
                  >
                    <v-list-item-action>
                      <v-icon :color="popup.applicable_pages > 0 ? 'indigo darken-4' : ''">
                        {{ iconPage }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
            </v-col>


            <v-col cols="12" v-if="popup.type == 'text'">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="popup.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.popup.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Display Title
              <v-switch
                  v-model="popup.display_title"
                  :label="popup.display_title ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="popup.is_active"
                  :label="popup.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog"
        >
          Cancel
        </v-btn>

        <v-btn
            class="btn btn-primary"
            @click="createOrUpdate()"
            :loading="loading"
            v-if="checkIsAccessible('popups', 'create') || checkIsAccessible('popup', 'edit')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, url, requiredIf} from "vuelidate/lib/validators";
import PopupService from "@/services/cms/popup/PopupService";
import PageService from "@/services/cms/page/PageService";

const popup = new PopupService();

export default {
  validations: {
    popup: {
      name: { required },
      description: { required: requiredIf(function () {
        return this.popup.type == 'text';
        }) },
      type: { required },
      link_url: { url },
      video_link_url: { url },
    }
  },
  data() {
    return {
      activePicker: null,
      loading: false,
      menuStartDate: false,
      menuEndDate: false,
      dialog: false,
      edit: false,
      title: '',
      errors: [],
      types: [
          { name: "Image", value: "image" },
          { name: "Text", value: "text" }
      ],
      pages: [
        { name: "Home Page", value: "homepage" },
        { name: "Agent", value: "agent" },
        { name: "Student", value: "student" }
      ],
      popup: {
        name: '',
        description: '',
        display_title: true,
        type: 'text',
        uploadFile: null,
        link_url: '',
        video_link_url: '',
        applicable_pages: [],
        start_date: '',
        end_date: '',
        is_active: true
    },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  computed: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    selectAllPage () {
      return this.popup.applicable_pages.length === this.pages.length
    },
    selectedPage () {
      return this.popup.applicable_pages.length > 0 && !this.selectAllPage
    },
    iconPage () {
      if (this.selectAllPage) return 'mdi-close-box'
      if (this.selectedPage) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    createPopup() {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editPopup(popup) {
      this.title = 'Edit';
      this.dialog = true;
      this.edit = true;
      this.popup = popup;
      if(popup.applicable_pages == null) {
        this.popup.applicable_pages = []
      }
    },

    allSelectGrades() {
      this.$nextTick(() => {
        if (this.selectAllPage) {
          this.popup.applicable_pages = []
        } else {
          this.popup.applicable_pages = [];
          this.pages.forEach(page => {
            this.popup.applicable_pages.push(page.value);
          });
        }
      })
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.popup) {
        if (key === "uploadFile" && this.popup[key] != null && this.popup[key] != undefined) {
          formData.append('uploadFile', this.popup[key]);
        }else {
          if(this.popup[key]){
            formData.append(key,this.popup[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {

        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      let form = this.convertToFormData();
      popup
          .create(form)
          .then(response => {
            this.$snotify.success("Popup Created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });

    },
    update: function () {
      let form = this.convertToFormData();
      popup
          .update(this.popup.id, form)
          .then((response) => {
            this.$snotify.success("Popup Updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.popup = {
        name: '',
        description: '',
        display_title: true,
        type: 'text',
        uploadFile: null,
        link_url: '',
        video_link_url: '',
        applicable_pages: [],
        start_date: '',
        end_date: '',
        is_active: true
      };
    },
  }
}
</script>

<style scoped>
  #logo_preview{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
</style>
